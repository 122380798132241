.container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.container > * {
    flex-shrink: 0;
}

.item {
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--dark-white);
    padding: 0 10px;
}

.item:last-child {
    padding-right: 0;
}

.item:first-child {
    padding-left: 0;
}

.itemActive {
    composes: item;
    cursor: pointer;
    color: var(--dark-gray-p100);
}

.itemActive:hover {
    color: var(--dark-primary-main)
}
