.container {
    --input-horizontal-padding: 10px;

    display: flex;
    border-radius: 10px;
    background-color: var(--dark-gray-p400);
    border: 1px solid var(--dark-gray-p400);
    align-items: center;
    min-height: 36px;
    box-sizing: border-box;
    cursor: text;
}

.containerFocus {
    composes: container;
    border-color: var(--dark-white);
}

.realInput {
    outline: none;
    font-size: 14px;
    line-height: 16px;
    color: var(--dark-gray-p100);
    background-color: transparent;
    flex-grow: 1;
    border: none;
    width: 0;
    padding: 0 5px;
}

.realInput::placeholder {
    color: var(--dark-gray-p100);
}

.realInput:focus {
    color: var(--dark-white);
}

.realInput:disabled::placeholder {
    color: var(--dark-gray-mian);
}
