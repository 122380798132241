.menu {
    --context-menu-radius: 10px;

    border: 1px solid var(--dark-gray-p400);
    border-radius: var(--context-menu-radius);
    min-width: 145px;
    background-color: #0F111C;
    overflow: auto;
}

.menu::-webkit-scrollbar-track {
    background-color: #0F111C;
    border-top-right-radius: var(--context-menu-radius);
    border-bottom-right-radius: var(--context-menu-radius);
}

.menu::-webkit-scrollbar-thumb {
    border: 5px solid #0F111C;
}

.menuItemBase {
    padding: 10px;
}

.menuItem {
    composes: menuItemBase;
    cursor: pointer;
}

.menuItem:hover {
    background-color: var(--dark-secondary-fmain);
}

.error {
    padding: 10px;
    max-width: 200px;
    word-break: break-all;
}